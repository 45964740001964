import './App.css'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { getCurrentRoute, getPairIdFromRoute, isDarkMode } from './util/constants'
import { switchNetwork } from './util/web3Methods'

class App extends Component {
  constructor() {
    super()
    this.state = {
      showInvalidNetwork: false,
      showSignIn: false,
      loading: true,
      selectedPair: null,
      accountToggle: false,
      networkToggle: false,
      toggleAdvancedTooltip: false,
      connecting: null,
      disconnecting: null
    }
    this.loadLayoutMode()
  }

  componentDidMount = () => {
    window.location.href = "https://app.auctus.org";
  }
  

  showSignInModal = (redirectUrl, context) => {
    this.setState({showSignIn: true, redirectUrl: redirectUrl})
  }

  onCloseSignIn = (navigate, context) => {
    var validNetwork = context && context.web3 && context.web3.validNetwork
    if (!validNetwork) {
      if (context && context.web3 && context.web3.isBrowserProvider) {
        this.setState({showSignIn: false}, () => {
          switchNetwork().then(() => this.setState({showInvalidNetwork: false})).catch((err) => {
            console.error(err)
            this.setState({showInvalidNetwork: true})
          })
        })
      } else {
        this.setState({showSignIn: false, showInvalidNetwork: true})
      }
    } else  {
      this.setState({showSignIn: false, showInvalidNetwork: false})
    }
  }

  onCloseInvalidNetwork = () => {
    this.setState({showSignIn: false, showInvalidNetwork: false})
  }

  onChangeNetwork = (network) => {
    this.setState({networkToggle:!this.state.networkToggle, accountToggle:!this.state.accountToggle})
  }

  onChangeAccount = (account, previousAccount) => {
    if (!account) {
      if (window.location.pathname.indexOf("advanced") >= 0) {
        this.props.history.push('/')
      }
    }
    else {
      if (!previousAccount) {
        if (this.state.redirectUrl) {
          this.props.history.push(this.state.redirectUrl) 
          this.setState({redirectUrl: null})
        }
      }
      this.setState({accountToggle:!this.state.accountToggle})
    }
  }

  onLoaded = () => {
    this.setState({loading: false})
  }

  onPairSelected = (pair) => {
    this.setState({selectedPair: pair})
    var route = getCurrentRoute(this.props.location)
    if (route) {
      var currentPairId = getPairIdFromRoute(this.props.location)
      if (pair && currentPairId !== pair.id) {
        this.props.history.push(route + pair.id)
      }      
    }
  }

  onPairsLoaded = (pairs) => {
    this.setState({pairs: pairs})
  }

  setLayoutMode = (isDarkMode) => {
    if (isDarkMode) {
      if (!document.body.classList.contains("dark-mode")) {
        document.body.classList.remove('light-mode')
        document.body.classList.add('dark-mode')
      }
      window.localStorage.setItem('LAYOUT_MODE', '1')
    }
    else {
      if (!document.body.classList.contains("light-mode")) {
        document.body.classList.remove('dark-mode')
        document.body.classList.add('light-mode')
      }
      window.localStorage.setItem('LAYOUT_MODE', '0')
    }
  }

  loadLayoutMode = () => {
    this.setLayoutMode(isDarkMode())
  }

  onConnect = (ok) => {
    this.setState({connecting: null, showSignIn: !ok})
  }

  render() {
    return <div></div>
  }
}
export default withRouter(App)
